import React from "react";

const Footer = () => {
    return (
        <footer className="container footer">
<a className="primary visible-mobile linkedin-mobile" href="https://www.linkedin.com/in/mohij/" target="_blank"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.125 14H0.21875V4.65625H3.125V14ZM1.65625 3.40625C0.75 3.40625 0 2.625 0 1.6875C0 1.09375 0.3125 0.53125 0.8125 0.25C1.34375 -0.0625 2 -0.0625 2.5 0.25C3.03125 0.53125 3.34375 1.09375 3.34375 1.6875C3.34375 2.625 2.59375 3.40625 1.65625 3.40625ZM13.9688 14H11.0938V9.46875C11.0938 8.375 11.0625 7 9.5625 7C8.0625 7 7.84375 8.15625 7.84375 9.375V14H4.9375V4.65625H7.71875V5.9375H7.75C8.15625 5.21875 9.09375 4.4375 10.5 4.4375C13.4375 4.4375 14 6.375 14 8.875V14H13.9688Z" fill="white"></path></svg><span>View Profile</span></a>
            <div className="row">
                <div className="col">
                    <div className="email">
                        <span> Say hi</span>
                        <a href='mailto:design.hijas@gmail.com'>design.hijas@gmail.com</a>
                    </div>
                </div>
                <div className="col">
                    <div className="social">
                        <a href="#linkedin">
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1_458)">
                                    <path d="M30.3992 0.415527H3.13019C1.82419 0.415527 0.768188 1.44653 0.768188 2.72153V30.1035C0.768188 31.3775 1.82419 32.4155 3.13019 32.4155H30.3992C31.7062 32.4155 32.7682 31.3775 32.7682 30.1095V2.72153C32.7682 1.44653 31.7062 0.415527 30.3992 0.415527ZM10.2622 27.6845H5.51219V12.4095H10.2622V27.6845ZM7.88719 10.3275C6.36219 10.3275 5.13119 9.09653 5.13119 7.57753C5.13119 6.05953 6.36219 4.82753 7.88719 4.82753C9.40619 4.82753 10.6372 6.05953 10.6372 7.57753C10.6372 9.09053 9.40619 10.3275 7.88719 10.3275ZM28.0372 27.6845H23.2932V20.2595C23.2932 18.4905 23.2622 16.2095 20.8242 16.2095C18.3562 16.2095 17.9812 18.1405 17.9812 20.1345V27.6845H13.2432V12.4095H17.7932V14.4965H17.8562C18.4872 13.2965 20.0372 12.0285 22.3432 12.0285C27.1492 12.0285 28.0372 15.1905 28.0372 19.3035V27.6845Z" fill="#030F49" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_458">
                                        <rect width="32" height="32" fill="white" transform="translate(0.768188 0.415527)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </a>
                        <a href="#CV">
                            <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.82989 9.57961L2.8706 20.6221C2.03742 23.7312 1.62083 25.2857 1.90774 26.6353C2.16011 27.8225 2.7819 28.8992 3.68397 29.7114C4.70949 30.6345 6.26423 31.0511 9.37371 31.8842L16.3505 33.7533C19.46 34.5863 21.0148 35.0029 22.3645 34.716C23.5518 34.4637 24.6288 33.8421 25.441 32.94C26.3644 31.9147 26.7811 30.3602 27.6142 27.2512L30.5735 16.2086C31.4066 13.0996 31.8233 11.5451 31.5363 10.1955C31.284 9.00835 30.6622 7.93155 29.7601 7.11945C28.7346 6.19621 27.1799 5.77968 24.0704 4.94661L17.0936 3.07748C13.9841 2.24442 12.4294 1.82789 11.0796 2.11476C9.89223 2.36709 8.81527 2.98878 8.00305 3.89072C7.07966 4.91608 6.66307 6.47059 5.82989 9.57961ZM11.2643 9.49308C11.4012 8.9821 11.9265 8.67885 12.4376 8.81577L25.3925 12.2865C25.9035 12.4234 26.2069 12.9487 26.07 13.4597C25.9331 13.9706 25.4077 14.2739 24.8966 14.137L11.9417 10.6662C11.4306 10.5293 11.1273 10.0041 11.2643 9.49308ZM10.9498 14.3671C10.4387 14.2302 9.91342 14.5334 9.77648 15.0444C9.63955 15.5554 9.94283 16.0807 10.4539 16.2176L23.4089 19.6883C23.9199 19.8253 24.4452 19.522 24.5821 19.011C24.7192 18.5 24.4158 17.9748 23.9047 17.8379L10.9498 14.3671ZM9.46218 19.9185C8.95113 19.7816 8.42582 20.0848 8.28888 20.5958C8.15194 21.1068 8.45523 21.632 8.96629 21.7689L16.3691 23.7522C16.8802 23.8891 17.4054 23.5859 17.5425 23.0749C17.6794 22.5639 17.3761 22.0387 16.865 21.9018L9.46218 19.9185Z" fill="#030F49" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.8706 20.6221L5.82989 9.57961C6.66307 6.47059 7.07966 4.91608 8.00305 3.89072C8.81527 2.98878 9.89223 2.36709 11.0796 2.11476C12.4294 1.82789 13.9841 2.24442 17.0936 3.07748L24.0704 4.94661C27.1799 5.77968 28.7346 6.19621 29.7601 7.11945C30.6622 7.93155 31.284 9.00835 31.5363 10.1955C31.8233 11.5451 31.4066 13.0996 30.5735 16.2086L27.6142 27.2512C26.7811 30.3602 26.3644 31.9147 25.441 32.94C24.6288 33.8421 23.5518 34.4637 22.3645 34.716C21.0148 35.0029 19.46 34.5863 16.3505 33.7533L9.37371 31.8842C6.26423 31.0511 4.70949 30.6345 3.68397 29.7114C2.7819 28.8992 2.16011 27.8225 1.90774 26.6353C1.62083 25.2857 2.03742 23.7312 2.8706 20.6221ZM11.2643 9.49308C11.4012 8.9821 11.9265 8.67885 12.4376 8.81577L25.3925 12.2865C25.9035 12.4234 26.2069 12.9487 26.07 13.4597C25.9331 13.9706 25.4077 14.2739 24.8966 14.137L11.9417 10.6662C11.4306 10.5293 11.1273 10.0041 11.2643 9.49308ZM10.9498 14.3671C10.4387 14.2302 9.91342 14.5334 9.77648 15.0444C9.63955 15.5554 9.94283 16.0807 10.4539 16.2176L23.4089 19.6883C23.9199 19.8253 24.4452 19.522 24.5821 19.011C24.7192 18.5 24.4158 17.9748 23.9047 17.8379L10.9498 14.3671ZM9.46218 19.9185C8.95113 19.7816 8.42582 20.0848 8.28888 20.5958C8.15194 21.1068 8.45523 21.632 8.96629 21.7689L16.3691 23.7522C16.8802 23.8891 17.4054 23.5859 17.5425 23.0749C17.6794 22.5639 17.3761 22.0387 16.865 21.9018L9.46218 19.9185Z" fill="#030F49" />
                            </svg>

                        </a>
                        <a href="#figma">
                            <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_35_167)">
                                    <path d="M11.6055 17.4154C11.6055 15.9351 12.1935 14.5155 13.2402 13.4687C14.2869 12.422 15.7066 11.834 17.1869 11.834C18.6671 11.834 20.0868 12.422 21.1335 13.4687C22.1802 14.5155 22.7683 15.9351 22.7683 17.4154C22.7683 18.8957 22.1802 20.3153 21.1335 21.362C20.0868 22.4087 18.6671 22.9968 17.1869 22.9968C15.7066 22.9968 14.2869 22.4087 13.2402 21.362C12.1935 20.3153 11.6055 18.8957 11.6055 17.4154V17.4154Z" fill="#1ABCFE" />
                                    <path d="M0.442627 28.5785C0.442627 27.0982 1.03067 25.6785 2.07738 24.6318C3.1241 23.5851 4.54375 22.9971 6.02403 22.9971H11.6054V28.5785C11.6054 30.0587 11.0174 31.4784 9.97067 32.5251C8.92395 33.5718 7.5043 34.1599 6.02403 34.1599C4.54375 34.1599 3.1241 33.5718 2.07738 32.5251C1.03067 31.4784 0.442627 30.0587 0.442627 28.5785H0.442627Z" fill="#0ACF83" />
                                    <path d="M11.6055 0.671387V11.8342H17.1869C18.6671 11.8342 20.0868 11.2461 21.1335 10.1994C22.1802 9.15271 22.7683 7.73306 22.7683 6.25278C22.7683 4.77251 22.1802 3.35286 21.1335 2.30614C20.0868 1.25943 18.6671 0.671387 17.1869 0.671387L11.6055 0.671387Z" fill="#FF7262" />
                                    <path d="M0.442627 6.25278C0.442627 7.73306 1.03067 9.15271 2.07738 10.1994C3.1241 11.2461 4.54375 11.8342 6.02403 11.8342H11.6054V0.671387H6.02403C4.54375 0.671387 3.1241 1.25943 2.07738 2.30614C1.03067 3.35286 0.442627 4.77251 0.442627 6.25278H0.442627Z" fill="#F24E1E" />
                                    <path d="M0.442627 17.4154C0.442627 18.8957 1.03067 20.3153 2.07738 21.362C3.1241 22.4087 4.54375 22.9968 6.02403 22.9968H11.6054V11.834H6.02403C4.54375 11.834 3.1241 12.422 2.07738 13.4687C1.03067 14.5155 0.442627 15.9351 0.442627 17.4154H0.442627Z" fill="#A259FF" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_35_167">
                                        <rect width="22.3256" height="33.4884" fill="white" transform="translate(0.442627 0.671387)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;