import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create the context
const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const location = useLocation();
    const [pageVisits, setPageVisits] = useState([]);
    const [currentPageStart, setCurrentPageStart] = useState(Date.now());

    useEffect(() => {
        const now = Date.now();
        
        // Only add new entry if path changed
        if (pageVisits.length === 0 || location.pathname !== pageVisits[pageVisits.length - 1]?.path) {
            // Calculate duration for previous page
            const duration = now - currentPageStart;
            
            setPageVisits(prev => [...prev, {
                path: location.pathname,
                startTime: now,
                duration: duration,
                durationInSeconds: Math.round(duration / 1000),
                timestamp: new Date(now).toISOString()
            }]);
            
            setCurrentPageStart(now);
        }
    }, [location.pathname, currentPageStart, pageVisits]);

    const getNavigationHistory = () => {
        const now = Date.now();
        const currentDuration = now - currentPageStart;
        
        // Include current page in history
        return [...pageVisits, {
            path: location.pathname,
            startTime: currentPageStart,
            duration: currentDuration,
            durationInSeconds: Math.round(currentDuration / 1000),
            timestamp: new Date(currentPageStart).toISOString()
        }];
    };

    return (
        <NavigationContext.Provider value={{ 
            getNavigationHistory,
            currentPath: location.pathname,
            hasHistory: pageVisits.length > 1
        }}>
            {children}
        </NavigationContext.Provider>
    );
};

// Custom hook to use the navigation context
export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export default NavigationContext;