import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import contentfulClient from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const ArticleDetail = () => {
  const fallbackArticle = {
    reload: 1,
    name: 'Loading...',
    shortDescription: 'Loading description...',
    projectOverview2: 'Loading project overview...',
    innerBannerColor: '#f5f5f5',
    detail: {
      content: []
    },
    thumbnail: {
      fields: {
        file: {
          url: '#'
        }
      }
    },
    taksLabel: 'Loading,Tags,Here',
  };

  const { id } = useParams();
  const location = useLocation();
  const initialArticleItem = location.state?.articleItem || null; // Start with null, not fallback
  const initialAllItems = location.state?.allItems || [];

  const [articleItem, setArticleItem] = useState(initialArticleItem);
  const [allItems, setAllItems] = useState(initialAllItems);
  const [headings, setHeadings] = useState([]);
  const [nextArticle, setNextArticle] = useState(null);
  const observerRef = useRef(null);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  useEffect(() => {
    const cache = new Map();
  
    const fetchArticleData = async () => {
      try {
        const cacheKey = `article-${id}`;
        console.log('Querying article with id:', id);
  
        // Check Cache
        if (cache.has(cacheKey)) {
          console.log('📦 Using cached data for id:', id);
          setArticleItem(cache.get(cacheKey));
          return;
        }
  
        // Fetch Data from Contentful
        console.log('🚀 Fetching article data from Contentful for id:', id);
  
        const response = await contentfulClient.getEntries({
          content_type: 'articles',
          'fields.name[match]': id, // Use partial or case-insensitive match
          select: 'fields',
          limit: 1,
        });
  
        console.log('Contentful response:', response);
  
        if (response.items && response.items.length > 0) {
          console.log('✅ Article data found for id:', id);
          const articleData = response.items[0].fields;
  
          // Cache and Set Data
          cache.set(cacheKey, articleData);
          setArticleItem(articleData);
        } else {
          console.warn('⚠️ No article data found for id:', id);
          setArticleItem(fallbackArticle); // Fallback for empty response
        }
      } catch (error) {
        console.error('❌ Error fetching article data:', error);
        setArticleItem(fallbackArticle); // Fallback for fetch error
      }
    };
  
    // Fetch if articleItem is null or empty
    if (!articleItem || articleItem.name === fallbackArticle.name) {
      fetchArticleData();
    }
  }, [id, articleItem]);

  useEffect(() => {
    if (articleItem && allItems.length > 0) {
      const currentIndex = allItems.findIndex(item => item.fields.name === articleItem.name);
      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % allItems.length;
        const nextItem = allItems[nextIndex];
        setNextArticle(nextItem.fields);
      }
    }
  }, [articleItem, allItems]);

  useEffect(() => {
    if (articleItem && articleItem.detail) {
      const newHeadings = articleItem.detail.content
        .filter(node => node.nodeType === BLOCKS.HEADING_3)
        .map(node => {
          const headingText = node.content[0]?.value || '';
          const headingId = headingText.toLowerCase().replace(/\s+/g, '-');
          return { text: headingText, id: headingId };
        });
      setHeadings(newHeadings);
    }
  }, [articleItem]);

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) setNextArticle(entry.target.id);
        });
      }, { threshold: 0.3 });
    }

    headings.forEach((heading) => {
      const section = document.getElementById(heading.id);
      if (section) observerRef.current.observe(section);
    });

    return () => observerRef.current?.disconnect();
  }, [headings]);

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node) => (
        <h3 id={node.content[0]?.value.toLowerCase().replace(/\s+/g, '-')}>
          {node.content[0]?.value}
        </h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          src={node.data.target.fields.file.url}
          alt={node.data.target.fields.title || ''}
          className="w-100"
        />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const isEmpty = !node.content || node.content.every(child => child.value === '');
        if (isEmpty) {
          return <div className="empty-space">&nbsp;</div>;
        }
        return <p>{children}</p>;
      },
    },
  };

  if (!articleItem) return <p>Loading...</p>;

  return (
    <div className="article-detail-page container">
      <div className="row justify-content-center mb-4">
        <div className="col-md-8">
          <h2 className='main-title'>{articleItem.name || fallbackArticle.name}</h2>
          {/* <p>{articleItem.shortDescription || fallbackArticle.shortDescription}</p> */}

          <div className='col-md-12 mb-4 mt-4'>
                <div className='label-wrap mt-0'>
                  {articleItem.labels?.split(',').map((label, index) => (
                    <span key={`task-${index}`}>{label.trim()}</span>
                  ))}
                </div>
              </div>
              <hr/>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 content content-article">
          {articleItem.articleDetails && documentToReactComponents(articleItem.articleDetails, renderOptions)}
        </div>
      </div>

      {nextArticle && (
        <div className="container">
          <Link
            className="wrap-next-case container-fluid"
            to={`/article/${nextArticle.name}`}
            state={{ articleItem: nextArticle, allItems }}
          >
            <div
              className="next-case-study mt-5 pt-10 text-center"
              style={{ backgroundColor: nextArticle.innerBannerColor }}
            >
              <div className="details">
                <p>Next Article</p>
                <h4>{nextArticle.name}</h4>
              </div>
              {nextArticle.thumbnail && (
                <img src={nextArticle.thumbnail.fields.file.url} alt={nextArticle.name} />
              )}
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;