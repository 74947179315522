// src/components/PortfolioPage.js
import React, { useEffect, useState } from 'react';
import Portfolio from './Portfolio';



const CaseStudies = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="portfolio-page">
            <div className='container '>
                <div className="row">
                    <div className="col-md-6 mb-5">
                        <h1 className="name-heading">Case Studies</h1>
                        <div className="banner-text-animated" style={{ animationDelay: `0.1s` }}>
                            <h4>Hand-picked projects that showcase my approach to solving complex design challenges.</h4>
                        </div>
                    </div>
                </div>
                <Portfolio showFeatured={false} />
            </div>

        </div>
    );
};

export default CaseStudies;