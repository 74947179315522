import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';
import CvSection from "./CvDetails";
import Gallery from "./Gallery";
import LazyImage from "./Comps/LazyImage";

const LocationIcon = () => (
  <svg 
    className="ms-4 me-2" 
    width="20" 
    height="70" 
    viewBox="0 0 12 16" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625C3.65625 13.5938 0 8.75 0 6C0 2.6875 2.6875 0 6 0C9.3125 0 12 2.6875 12 6C12 8.75 8.34375 13.5938 6.71875 15.625ZM6 4C5.28125 4 4.625 4.40625 4.25 5C3.90625 5.625 3.90625 6.40625 4.25 7C4.625 7.625 5.28125 8 6 8C6.6875 8 7.34375 7.625 7.71875 7C8.0625 6.40625 8.0625 5.625 7.71875 5C7.34375 4.40625 6.6875 4 6 4Z" 
      fill="#030F49" 
    />
  </svg>
);

const About = () => {
  const location = useLocation();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [bannerImageLoaded, setBannerImageLoaded] = useState(false);
  const bannerRef = useRef(null);

  // Cleanup function to prevent memory leaks
  useEffect(() => {
    return () => {
      setPageLoaded(false);
      setBannerImageLoaded(false);
    };
  }, []);

  // Preload banner image
  useEffect(() => {
    const img = new Image();
    img.src = "https://images.ctfassets.net/7lqh82unq55r/6JC72cxjgn6wSYAGDV4wTO/59ee6e0ea27937250db1f3cc5fd70008/_1171275311.png";
    img.onload = () => {
      setPageLoaded(true);
    };
  }, []);

  // Reset scroll position
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, [location.pathname]);

  return (
    <div className="about-page">
      <div 
        ref={bannerRef}
        className={`BannerAbout ${bannerImageLoaded ? 'loaded' : ''}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner-content">
                <h1 className="name-heading">Mohammed</h1>
                <h1 className="d-flex align-items-end location-heading">
                  Hijas 
                  <span className="me-2 ms-4">
                    /
                    <LocationIcon />
                    Abu Dhabi
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-md-6">
              {pageLoaded && (
                <LazyImage
                  src="https://images.ctfassets.net/7lqh82unq55r/6JC72cxjgn6wSYAGDV4wTO/59ee6e0ea27937250db1f3cc5fd70008/_1171275311.png"
                  alt="About Banner"
                  className="w-100"
                  onLoad={() => {
                    setBannerImageLoaded(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {pageLoaded && (
        <>
          <CvSection />
          <Gallery />
        </>
      )}
    </div>
  );
};

export default About;