import React, { useEffect, useRef } from 'react';
import { createClient } from 'contentful-management';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

// Constants
const PRODUCTION_DOMAIN = 'mohij.com';
const managementClient = createClient({
    accessToken: 'CFPAT-MhHaWd_tfwRhbdrQHnlybgli6HoZNlDCHKTrAACcE5o'
});

const SimpleVisitorTracker = () => {
    const updateInProgress = useRef(false);
    const mounted = useRef(false);

    const isTrackingEnabled = () => {
        const hostname = window.location.hostname;
        const isProductionDomain = hostname === PRODUCTION_DOMAIN || 
                                 hostname === `www.${PRODUCTION_DOMAIN}`;
        
        // Check both domain and environment variable if it exists
        const envTracking = process.env.REACT_APP_ENABLE_TRACKING === 'true';
        
        return isProductionDomain || envTracking;
    };

    const getOrCreateVisitorId = () => {
        let visitorId = localStorage.getItem('visitor_id');
        if (!visitorId) {
            visitorId = uuidv4();
            localStorage.setItem('visitor_id', visitorId);
        }
        return visitorId;
    };

    const getDeviceInfo = () => {
        const parser = new UAParser();
        const result = parser.getResult();
        
        return {
            device: result.device.type || 'Desktop',
            browser: result.browser.name || 'Unknown',
            osName: result.os.name || 'Unknown'
        };
    };

    const findExistingEntry = async (environment, visitorId) => {
        const query = {
            content_type: 'visitor',
            'fields.visitorId': visitorId
        };

        const entries = await environment.getEntries(query);
        console.log(`Found ${entries.items.length} entries for visitor ID ${visitorId}`);
        return entries.items;
    };

    const updateVisitorData = async () => {
        // Check if tracking should be enabled
        if (!isTrackingEnabled()) {
            console.log('Visitor tracking disabled in development environment');
            return;
        }

        // Prevent concurrent updates and check mounting
        if (updateInProgress.current || !mounted.current) {
            console.log('Update skipped: ' + 
                       (updateInProgress.current ? 'Update in progress' : 'Component not mounted'));
            return;
        }

        try {
            updateInProgress.current = true;

            const visitorId = getOrCreateVisitorId();
            const deviceInfo = getDeviceInfo();
            const currentTime = new Date().toISOString();

            console.log('Starting visitor data update...');
            const space = await managementClient.getSpace('7lqh82unq55r');
            const environment = await space.getEnvironment('master');

            const existingEntries = await findExistingEntry(environment, visitorId);
            
            const visitorFields = {
                visitorId: { 'en-US': visitorId },
                device: { 'en-US': deviceInfo.device },
                browser: { 'en-US': deviceInfo.browser },
                osName: { 'en-US': deviceInfo.osName },
                lastActive: { 'en-US': currentTime }
            };

            if (existingEntries.length > 0) {
                // Update primary entry
                const primaryEntry = existingEntries[0];
                console.log('Updating primary entry:', primaryEntry.sys.id);

                const freshEntry = await environment.getEntry(primaryEntry.sys.id);
                const updatedEntry = await freshEntry.update({ fields: visitorFields });
                await updatedEntry.publish();

                // Cleanup any duplicates
                if (existingEntries.length > 1) {
                    console.log('Cleaning up duplicate entries...');
                    for (let i = 1; i < existingEntries.length; i++) {
                        const duplicateEntry = existingEntries[i];
                        try {
                            if (duplicateEntry.sys.publishedVersion) {
                                await duplicateEntry.unpublish();
                            }
                            await duplicateEntry.delete();
                            console.log(`Deleted duplicate entry: ${duplicateEntry.sys.id}`);
                        } catch (error) {
                            console.error('Error deleting duplicate:', error);
                        }
                    }
                }
            } else {
                console.log('Creating new visitor entry');
                const newEntry = await environment.createEntry('visitor', {
                    fields: visitorFields
                });
                await newEntry.publish();
                console.log('New entry created successfully');
            }
        } catch (error) {
            console.error('Error in visitor tracking:', error);
            if (error.response) {
                console.error('API Error:', error.response.data);
            }
        } finally {
            updateInProgress.current = false;
        }
    };

    useEffect(() => {
        mounted.current = true;
        
        if (isTrackingEnabled()) {
            console.log('Initializing visitor tracking...');
            updateVisitorData();
        } else {
            console.log('Visitor tracking disabled in development environment');
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    return null;
};

export default SimpleVisitorTracker;