import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import contentfulClient from "../contentfulClient";

const Community = (props) => {
  const [communityItems, setCommunityItems] = useState([]);

  useEffect(() => {
    const fetchCommunityItems = async () => {
      try {
        const response = await contentfulClient.getEntries({
          content_type: "community",
        });

        const sortedItems = response.items.sort((a, b) => {
          if (a.fields.order && b.fields.order) return a.fields.order - b.fields.order;
          if (a.fields.order) return -1;
          if (b.fields.order) return 1;
          return 0;
        });

        setCommunityItems(sortedItems);
      } catch (error) {
        console.error("Error fetching Community items:", error);
      }
    };

    fetchCommunityItems();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerMode: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          dots: true,
          arrows: false
        }
      }
    ]
  };


  return (
    <div className="mt-4 pt-4 mb-4 pb-4">
      <h2 className="title-sub">
        <span>{props.stepNumber}</span> For the Community
      </h2>

      <Slider {...sliderSettings} className="community-slider">
        {communityItems.map((item) => (
          <div key={item.sys.id}>
            <div className="wrap border_r">
              <div className="thumbnail">
                {item.fields.image && (
                  <a rel="noreferrer" target="_blank" href={item.fields.url}>
                      <img
                      className="w-100"
                      alt="Figma"
                      src={item.fields.image.fields.file.url}
                      />
                      <div className="external">

                        <svg 
                          width="11" 
                          height="11" 
                          viewBox="0 0 11 11" 
                          stroke="#FFF" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path 
                            d="M10.0005 0.999547C10.0566 1.05574 10.1 1.1361 10.1 1.22482V7.96955C10.1 8.17331 9.93445 8.29438 9.77518 8.29438C9.68487 8.29438 9.60088 8.26689 9.53936 8.20537C9.47784 8.14385 9.45035 8.05986 9.45035 7.96955V2.02772L1.44308 10.0075L10.0005 0.999547ZM10.0005 0.999547C9.94426 0.943358 9.8639 0.9 9.77518 0.9H3.03044C2.82669 0.9 2.70562 1.06555 2.70562 1.22482C2.70562 1.31513 2.73311 1.39912 2.79463 1.46064C2.85615 1.52216 2.94014 1.54965 3.03044 1.54965H8.97228L0.992521 9.55692L10.0005 0.999547Z" 
                            fill="#FFF" 
                            stroke="FFF" 
                            strokeWidth="0.4" 
                          />
                        </svg>
                      </div>
                  </a>
                  
                )}
              </div>
              <div className="community-item">
                <div className="information">
               
                 
                 <div className="p-4">

                 <h4>{item.fields.projectName}</h4>


                 <div className="label-wrap">
                  
                  {item.fields.tags?.split(',').map((label, index) => (
                      <span key={`task-${index}`}>{label.trim()}</span>
                    ))}
                  </div>

                  <a rel="noreferrer" target="_blank" href={item.fields.url}>
                    

                    <span>
                    View/Download
                    </span>
                    <svg 
                      width="11" 
                      height="11" 
                      viewBox="0 0 11 11" 
                      stroke="#FFF" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M10.0005 0.999547C10.0566 1.05574 10.1 1.1361 10.1 1.22482V7.96955C10.1 8.17331 9.93445 8.29438 9.77518 8.29438C9.68487 8.29438 9.60088 8.26689 9.53936 8.20537C9.47784 8.14385 9.45035 8.05986 9.45035 7.96955V2.02772L1.44308 10.0075L10.0005 0.999547ZM10.0005 0.999547C9.94426 0.943358 9.8639 0.9 9.77518 0.9H3.03044C2.82669 0.9 2.70562 1.06555 2.70562 1.22482C2.70562 1.31513 2.73311 1.39912 2.79463 1.46064C2.85615 1.52216 2.94014 1.54965 3.03044 1.54965H8.97228L0.992521 9.55692L10.0005 0.999547Z" 
                        fill="#161e47" 
                        stroke="#161e47" 
                        strokeWidth="0.4" 
                      />
                    </svg>
                  </a>
                 </div>



                  {/* <a
                    href={item.fields.url}
                    className="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   
                    <span>View in Community</span>

                    <svg 
                      width="11" 
                      height="11" 
                      viewBox="0 0 11 11" 
                      stroke="#FFF" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M10.0005 0.999547C10.0566 1.05574 10.1 1.1361 10.1 1.22482V7.96955C10.1 8.17331 9.93445 8.29438 9.77518 8.29438C9.68487 8.29438 9.60088 8.26689 9.53936 8.20537C9.47784 8.14385 9.45035 8.05986 9.45035 7.96955V2.02772L1.44308 10.0075L10.0005 0.999547ZM10.0005 0.999547C9.94426 0.943358 9.8639 0.9 9.77518 0.9H3.03044C2.82669 0.9 2.70562 1.06555 2.70562 1.22482C2.70562 1.31513 2.73311 1.39912 2.79463 1.46064C2.85615 1.52216 2.94014 1.54965 3.03044 1.54965H8.97228L0.992521 9.55692L10.0005 0.999547Z" 
                        fill="#FFF" 
                        stroke="FFF" 
                        strokeWidth="0.4" 
                      />
                    </svg>

                  </a> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Community;