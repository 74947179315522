// src/components/CvSection.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import contentfulClient from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const CvSection = () => {
  const [cvItems, setCvItems] = useState([]);


  const renderOptions = {
    renderNode: {
      'embedded-entry-inline': (node, children) => {
        const entry = node.data.target;
        return <p>{children}</p>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { fields } = node.data.target;
        const { description, file, title } = fields;


        return (
          <div className="embed-image logo-left">
            <div className='wrap-logo-left'>
            <img className="w-100 img-rounded" src={file.url} alt={title} />
            </div>
            <div className="details-rightx">
            <h3>{title}</h3>
            <p>{description}</p>
            </div>
          </div>
        );
      },
    },
  };


  useEffect(() => {
    const fetchCvItems = async () => {

      try {
        const response = await contentfulClient.getEntries({
          content_type: 'cvSection', // Ensure this matches your content type ID in Contentful
        });

        // Sort the items by the 'order' field in ascending order
        const sortedItems = response.items.sort((a, b) => {
          return a.fields.order - b.fields.order;
        });

        setCvItems(sortedItems);
      } catch (error) {
        console.error("Error fetching cv items:", error);
      }
    };

    fetchCvItems();
  }, []);

  return (
    <div>
      <div className="container">
       <div className='row justify-content-center'>
        <div className='col-md-9'>
        {cvItems.map((item) => (
          <div key={item.sys.id} className="single-cv-item">
            <div className="left-side me-3">
              <p>{item.fields.name}</p> 
            </div>
            <div className='right-side col'>
              {item.fields.details && documentToReactComponents(item.fields.details, renderOptions)}
            </div>
          </div>
        ))}
        </div>
       </div>
      </div>
    </div>
  );
};

export default CvSection;