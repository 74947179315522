// src/components/toolss.js
import React, { useEffect, useState } from 'react';
import contentfulClient from '../contentfulClient';

const Tools = () => {
  const [toolsItems, settoolsItems] = useState([]);

  useEffect(() => {
    const fetchtoolsItems = async () => {
      try {
        const response = await contentfulClient.getEntries({
          content_type: 'toolsLogos'
        });


        // Sort the items by the 'order' field in ascending order
        const sortedItems = response.items.sort((a, b) => a.fields.order - b.fields.order);
        settoolsItems(sortedItems);
      } catch (error) {
        console.error("Error fetching tools items:", error);
      }
    };

    fetchtoolsItems();
  }, []);

  return (
    <div>
      {/* <h2 className='title-sub'> <span>02</span> Tools</h2> */}
      <div className="tools-grid row align-items-center justify-content-center">
        {toolsItems.map((item) => (
            <div className='col-md-2 single-tool' key={item.sys.id}>
                <img title={item.fields.name} className='w-100' src={item.fields.logo.fields.file.url}/>
            </div>
        ))}
      </div>
    </div>
  );
};

export default Tools;