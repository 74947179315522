import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get current route location

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }

        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [isOpen]);

    // Function to check if link is active
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <nav className={`col-10 text-right ${isOpen ? 'isOpen' : ''}`}>
            <div onClick={toggleMenu} className={`hamburger ${isOpen ? 'isOpen' : ''}`}><div></div></div>

            <div className='nav-wrap'>
                <Link 
                    className={`home-mobile ${isActive('/')}`} 
                    onClick={toggleMenu} 
                    to="/"
                />
                <Link 
                    className={isActive('/about')} 
                    onClick={toggleMenu} 
                    to="/about"
                >
                    About Me
                </Link>
                <Link 
                    className={isActive('/Case_Studies')} 
                    onClick={toggleMenu} 
                    to="/Case_Studies"
                >
                    Case Studies
                </Link>
                <Link 
                    className={isActive('/Archive')} 
                    onClick={toggleMenu} 
                    to="/Archive"
                >
                    Archive
                </Link>
                <a onClick={toggleMenu} className='' href='mailto:design.hijas@gmail.com'>
                    Contact 
                    <svg className='ms-2' width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0005 0.999547C10.0566 1.05574 10.1 1.1361 10.1 1.22482V7.96955C10.1 8.17331 9.93445 8.29438 9.77518 8.29438C9.68487 8.29438 9.60088 8.26689 9.53936 8.20537C9.47784 8.14385 9.45035 8.05986 9.45035 7.96955V2.02772L1.44308 10.0075L10.0005 0.999547ZM10.0005 0.999547C9.94426 0.943358 9.8639 0.9 9.77518 0.9H3.03044C2.82669 0.9 2.70562 1.06555 2.70562 1.22482C2.70562 1.31513 2.73311 1.39912 2.79463 1.46064C2.85615 1.52216 2.94014 1.54965 3.03044 1.54965H8.97228L0.992521 9.55692L10.0005 0.999547Z" fill="#030F49" stroke="black" strokeWidth="0.2" />
                    </svg>
                </a>
            </div>

            <a className="primary" href='https://www.linkedin.com/in/mohij/' target='_blank' rel="noreferrer">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 14H0.21875V4.65625H3.125V14ZM1.65625 3.40625C0.75 3.40625 0 2.625 0 1.6875C0 1.09375 0.3125 0.53125 0.8125 0.25C1.34375 -0.0625 2 -0.0625 2.5 0.25C3.03125 0.53125 3.34375 1.09375 3.34375 1.6875C3.34375 2.625 2.59375 3.40625 1.65625 3.40625ZM13.9688 14H11.0938V9.46875C11.0938 8.375 11.0625 7 9.5625 7C8.0625 7 7.84375 8.15625 7.84375 9.375V14H4.9375V4.65625H7.71875V5.9375H7.75C8.15625 5.21875 9.09375 4.4375 10.5 4.4375C13.4375 4.4375 14 6.375 14 8.875V14H13.9688Z" fill="white" />
                </svg>
                <span>View Linkedin Profile</span>
            </a>
        </nav>
    );
}

export default NavBar;