// ScrollManager.js
import { useLayoutEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollManager({ onScrollRestored }) {
    const location = useLocation();
    const isRestoringScroll = useRef(false);

    useLayoutEffect(() => {
        const path = location.pathname;

        // Only apply scroll restoration for the homepage
        if (path === "/") {
            const savedPosition = sessionStorage.getItem(`scroll-position-${path}`);
            
            if (savedPosition !== null) {
                isRestoringScroll.current = true;

                // Use requestAnimationFrame to restore scroll position precisely
                const restoreScroll = () => {
                    window.scrollTo(0, parseInt(savedPosition, 10));

                    if (window.scrollY !== parseInt(savedPosition, 10)) {
                        requestAnimationFrame(restoreScroll);
                    } else {
                        isRestoringScroll.current = false;
                        onScrollRestored(); // Notify that scroll restoration is complete
                    }
                };

                restoreScroll();
            }
        }

        const handleScroll = () => {
            if (!isRestoringScroll.current && path === "/") {
                sessionStorage.setItem(`scroll-position-${path}`, window.scrollY);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location, onScrollRestored]);

    return null;
}

export default ScrollManager;