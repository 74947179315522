import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful-management';
import { UAParser } from 'ua-parser-js';
import { useNavigation } from './NavigationContext';

// Constants
const MAX_CHARS = 200;
const DISPLAY_DELAY = 15000; // 30 seconds

const managementClient = createClient({
    accessToken: 'CFPAT-MhHaWd_tfwRhbdrQHnlybgli6HoZNlDCHKTrAACcE5o'
});

// Utility function to get user details
const getUserDetails = () => {
    const parser = new UAParser();
    const result = parser.getResult();
    
    return {
        device: result.device.type || 'Desktop',
        browser: result.browser.name || 'Unknown',
        platform: result.os.name || 'Unknown',
        osName: result.os.name || 'Unknown',
        osVersion: result.os.version || 'Unknown'
    };
};

// Format navigation history
const formatNavigationHistory = (history) => {
    if (!history || !Array.isArray(history)) return '';

    const formatPath = (path) => {
        const decodedPath = decodeURIComponent(path);
        
        // Format different paths
        switch(decodedPath) {
            case '/':
                return 'Home Page';
            case '/about':
                return 'About Page';
            case '/Case_Studies':
                return 'Case Studies Overview';
            case '/Case_studies/Porsche Gaming Challenge ':
                return 'Porsche Gaming Challenge Case Study';
            default:
                return decodedPath.replace(/_/g, ' ').replace(/^\/?/, '');
        }
    };

    let formattedHistory = [];
    const referrer = document.referrer;

    // Add referrer information
    if (referrer) {
        const referrerDomain = new URL(referrer).hostname;
        formattedHistory.push(`Started from: ${referrerDomain}`);
    }

    // Process each page visit
    let previousPath = null;
    let totalTimeOnPath = 0;

    history.forEach((visit, index) => {
        const date = new Date(visit.timestamp);
        const timeString = date.toLocaleTimeString('en-US', { 
            hour: '2-digit', 
            minute: '2-digit' 
        });

        const formattedPath = formatPath(visit.path);
        const duration = visit.durationInSeconds;

        // Combine durations for same path
        if (previousPath === visit.path) {
            totalTimeOnPath += duration;
        } else {
            if (previousPath) {
                formattedHistory.push(
                    `${timeString}: Spent ${totalTimeOnPath} seconds on ${formatPath(previousPath)}`
                );
            }
            formattedHistory.push(`${timeString}: Navigated to ${formattedPath}`);
            totalTimeOnPath = duration;
        }

        previousPath = visit.path;

        // Add final duration for last path
        if (index === history.length - 1 && totalTimeOnPath > 0) {
            formattedHistory.push(
                `${timeString}: Spent ${totalTimeOnPath} seconds on ${formattedPath}`
            );
        }
    });

    // Add submission information
    formattedHistory.push(`${new Date().toLocaleTimeString('en-US', { 
        hour: '2-digit', 
        minute: '2-digit' 
    })}: Submitted rating form`);

    return formattedHistory.join('\n');
};

const StarRating = ({ onClose }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [hoverRating, setHoverRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestions, setSuggestions] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showError, setShowError] = useState(false);

    // Get navigation data
    const { getNavigationHistory } = useNavigation();

    // Check if user has already rated or dismissed
    useEffect(() => {
        const hasRated = localStorage.getItem('hasRated') === 'true';
        const hasDismissed = localStorage.getItem('hasDismissed') === 'true';

        if (!hasRated && !hasDismissed) {
            const timer = setTimeout(() => {
                setShowPopup(true);
            }, DISPLAY_DELAY);

            return () => clearTimeout(timer);
        }
    }, []);

    // Calculate remaining characters
    const remainingChars = MAX_CHARS - suggestions.length;

    // Handle mouse interactions for stars
    const handleMouseEnter = (rating) => {
        if (!isLoading && !isSubmitted) {
            setHoverRating(rating);
        }
    };

    const handleMouseLeave = () => {
        if (!isLoading && !isSubmitted) {
            setHoverRating(0);
        }
    };

    // Handle suggestions text input
    const handleSuggestionsChange = (e) => {
        const text = e.target.value;
        if (text.length <= MAX_CHARS) {
            setSuggestions(text);
            if (showError && text.trim()) {
                setShowError(false);
            }
        }
    };

    // Handle back button click
    const handleBackClick = () => {
        setShowSuggestions(false);
        setSelectedRating(0);
        setSuggestions('');
        setShowError(false);
    };

    // Handle close
    const handleClose = () => {
        localStorage.setItem('hasDismissed', 'true');
        onClose();
    };

    const sendRatingToContentful = async (userRating, suggestions) => {
        try {
            const space = await managementClient.getSpace('7lqh82unq55r');
            const environment = await space.getEnvironment('master');
    
            const navigationHistory = getNavigationHistory();
            const formattedHistory = formatNavigationHistory(navigationHistory);
            const previousPages = navigationHistory.slice(0, -1);
            const userDetails = getUserDetails();
    
            const entry = await environment.createEntry('rating', {
                fields: {
                    // Basic information
                    stars: { 'en-US': userRating },
                    submittedAt: { 'en-US': new Date().toISOString() },
                    suggestions: { 'en-US': suggestions || '' },
    
                    // Device information
                    device: { 'en-US': userDetails.device },
                    browser: { 'en-US': userDetails.browser },
                    platform: { 'en-US': userDetails.platform },
                    osName: { 'en-US': userDetails.osName },
                    osVersion: { 'en-US': userDetails.osVersion },
    
                    // Navigation information
                    referrer: { 'en-US': document.referrer || 'direct' },
                    hasNavigationHistory: { 'en-US': previousPages.length > 0 },
                    previousPath: { 'en-US': previousPages.length > 0 ? 
                        previousPages[previousPages.length - 1].path : 'direct' },
                    navigationHistory: { 'en-US': formattedHistory }
                    // Removed rawNavigationHistory as it doesn't exist in Contentful
                },
            });
    
            await entry.publish();
            return entry;
        } catch (error) {
            console.error('Error submitting rating:', error);
            throw error;
        }
    };

    // Submit feedback function
    const submitFeedback = async (rating) => {
        setIsLoading(true);
        try {
            await sendRatingToContentful(rating, suggestions);
            setIsSubmitted(true);
            localStorage.setItem('hasRated', 'true');
        } catch (error) {
            console.error('Error submitting rating:', error);
            setSelectedRating(0);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle rating selection
    const handleRatingClick = async (rating) => {
        if (isLoading) return;
        
        setSelectedRating(rating);
        
        if (rating <= 3) {
            setShowSuggestions(true);
        } else {
            await submitFeedback(rating);
        }
    };

    // Handle suggestions submission
    const handleSuggestionsSubmit = async () => {
        if (!suggestions.trim()) {
            setShowError(true);
            return;
        }
        await submitFeedback(selectedRating);
    };

    if (!showPopup) return null;

    const getPopupClassName = () => {
        let className = 'rating-popup';
        if (showSuggestions) className += ' text-area-open';
        if (isSubmitted) className += ' submitted';
        return className;
    };

    if (isSubmitted) {
        return (
            <div className={getPopupClassName()}>
                <div className="rating-container">
                    <button onClick={handleClose} className="close-button">
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <svg 
                        className="success-icon"
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth="2" 
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
                        />
                    </svg>
                    <h4 className="success-title">Thank You!</h4>
                    <p className="success-description">Your feedback helps me get better.</p>
                    <button
                        onClick={handleClose}
                        className="close-success-button primary w-100"
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    }

    if (showSuggestions) {
        return (
            <div className={getPopupClassName()}>
                <div className="rating-container">
                    <button onClick={handleClose} className="close-button">
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    
                    <div className="rating-content">
                        <h4 className="rating-title">How can I improve?</h4>
                        <p className="rating-description">Your suggestions will greatly contribute to enhancing my portfolio.</p>
                        
                        <div className="textarea-container">
                            <textarea
                                className={`suggestions-textarea ${showError ? 'error' : ''}`}
                                value={suggestions}
                                onChange={handleSuggestionsChange}
                                placeholder="Please share your suggestions..."
                                rows={4}
                                disabled={isLoading}
                                maxLength={MAX_CHARS}
                            />
                            {showError && (
                                <div className="error-message">Please provide your suggestions</div>
                            )}
                            <div className={`char-counter ${remainingChars <= 20 ? 'warning' : ''}`}>
                                {remainingChars} characters remaining
                            </div>
                        </div>
                        
                        <div className="button-group">
                            <button
                                onClick={handleBackClick}
                                className="back-button secondary"
                                disabled={isLoading}
                            >
                                Go Back
                            </button>
                            <button
                                onClick={handleSuggestionsSubmit}
                                className="submit-button primary"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                        
                        {isLoading && (
                            <div className="loading-text">
                                <div className="spinner"></div>
                                Submitting...
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={getPopupClassName()}>
            <div className="rating-container">
                <button onClick={handleClose} className="close-button">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                
                <div className="rating-content">
                    <h4 className="rating-title">Rate Your Experience!</h4>
                    <p className="rating-description">I'd love your feedback!</p>
                    
                    <div className="stars-container">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <button
                                key={star}
                                disabled={isLoading}
                                onMouseEnter={() => handleMouseEnter(star)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => handleRatingClick(star)}
                                className={`star-button ${
                                    (hoverRating || selectedRating) >= star ? 'active' : ''
                                }`}
                            >
                                ★
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StarRating;