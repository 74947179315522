import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import contentfulClient from '../contentfulClient';

const ArticleItem = React.memo(({ item }) => (
  <Link
    className="single-article"
    to={`/article/${item.fields.name}`}
    state={{ articleItem: item.fields, allItems: item.allItems }}
  >
    <div className="thumbnail">
      {item.fields.thumbnail && (
        <img
          src={item.fields.thumbnail.fields.file.url}
          alt={item.fields.title}
        />
      )}
    </div>
    <div className="article-item">
      <div className="information">
        <strong>{item.fields.articleType}</strong>
        <h3>{item.fields.name}</h3>
        <p>{item.fields.shortDescription}</p>
        <button className="primary">
          <span>Read More</span>
        </button>
      </div>
    </div>
  </Link>
));

const Articles = ({ showFeatured, stepNumber }) => {
  const [articleItems, setArticleItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchArticleItems = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      const cached = sessionStorage.getItem('articleItems');
      if (cached) {
        const { items, timestamp } = JSON.parse(cached);
        const isCacheValid = Date.now() - timestamp < 5 * 60 * 1000; // 5 minutes cache
        
        if (isCacheValid) {
          let sortedItems = items.sort((a, b) => a.fields.order - b.fields.order);
          if (showFeatured) {
            sortedItems = sortedItems.filter(item => item.fields.featured === true);
          }
          setArticleItems(sortedItems);
          setLoading(false);
          return;
        }
      }

      const response = await contentfulClient.getEntries({
        content_type: 'articles',
        include: 2,
      });

      let sortedItems = response.items.sort((a, b) => a.fields.order - b.fields.order);
      
      if (showFeatured) {
        sortedItems = sortedItems.filter(item => item.fields.featured === true);
      }

      sessionStorage.setItem('articleItems', JSON.stringify({
        items: response.items,
        timestamp: Date.now()
      }));

      setArticleItems(sortedItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching article items:", error);
      setError("Failed to load articles. Please try again later.");
      setLoading(false);
    }
  }, [showFeatured]);

  useEffect(() => {
    fetchArticleItems();
  }, [fetchArticleItems]);

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={fetchArticleItems}>Try Again</button>
      </div>
    );
  }

  return (
    <div>
      <h2 className='title-sub'><span>{stepNumber}</span> Writing</h2>
      
      {loading ? (
        <div className="article-grid-skeleton">
          {[1, 2, 3].map(i => (
            <div key={i} className="article-item-skeleton">
              <div className="skeleton-animation"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="article-grid">
          {articleItems.map((item) => (
            <ArticleItem
              key={item.sys.id}
              item={{...item, allItems: articleItems}}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(Articles);