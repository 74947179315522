import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import contentfulClient from '../contentfulClient';

const SideProjects = (props) => {
  const [articleItems, setarticleItems] = useState([]);

  useEffect(() => {
    const fetcharticleItems = async () => {
      try {
        const response = await contentfulClient.getEntries({
          content_type: 'sideProjects'
        });
        
        // Sort items based on order field
        const sortedItems = response.items.sort((a, b) => {
          // If both items have order fields, sort by order
          if (a.fields.order && b.fields.order) {
            return a.fields.order - b.fields.order;
          }
          // If only a has order, it should come first
          if (a.fields.order) return -1;
          // If only b has order, it should come first
          if (b.fields.order) return 1;
          // If neither has order, maintain original order
          return 0;
        });

        setarticleItems(sortedItems);
      } catch (error) {
        console.error("Error fetching article items:", error);
      }
    };

    fetcharticleItems();
  }, []);

  return (
    <div className='mt-4'>
      <h2 className='title-sub'><span>{props.stepNumber}</span> Side Experiments</h2>
      <div className="side-project-grid">
        {articleItems.map((item) => (
          <div className='wrap' key={item.sys.id}>
            <div className='thumbnail'>
            

              {item.fields.image && (
                <video
                  src={item.fields.image.fields.file.url}
                  loop={true} 
                  autoPlay={true} 
                  muted={true} 
                  playsInline={true} 
                  className={item.fields.class}
                />
              )}
            </div>
            <div className="article-item">
              <div className='information'>
                <h3>{item.fields.projectName}</h3>
                <a href={item.fields.url} className='primary' target='_blank' rel="noopener noreferrer">
                  <svg viewBox="0 0 16 16" id="svg10921612823">
                    <path d="M 8 8 C 8 8 9 5.322 9 3.429 C 9 1.535 8.552 0 8 0 C 7.448 0 7 1.535 7 3.429 C 7 5.322 8 8 8 8 Z M 8 8 C 8 8 9.186 10.601 10.525 11.94 C 11.864 13.279 13.266 14.047 13.657 13.657 C 14.047 13.266 13.279 11.864 11.94 10.525 C 10.601 9.186 8 8 8 8 Z M 8 8 C 8 8 10.678 7 12.571 7 C 14.465 7 16 7.448 16 8 C 16 8.552 14.465 9 12.571 9 C 10.678 9 8 8 8 8 Z M 8 8 C 8 8 5.399 9.186 4.06 10.525 C 2.721 11.864 1.953 13.266 2.343 13.657 C 2.734 14.047 4.136 13.279 5.475 11.94 C 6.814 10.601 8 8 8 8 Z M 8 8 C 8.002 8.006 9 10.68 9 12.571 C 9 14.465 8.552 16 8 16 C 7.448 16 7 14.465 7 12.571 C 7 10.678 8 8 8 8 Z M 8 8 C 8 8 5.322 7 3.429 7 C 1.535 7 0 7.448 0 8 C 0 8.552 1.535 9 3.429 9 C 5.322 9 8 8 8 8 Z M 8 8 C 8 8 10.601 6.814 11.94 5.475 C 13.279 4.136 14.047 2.734 13.657 2.343 C 13.266 1.953 11.864 2.722 10.525 4.06 C 9.186 5.399 8 8 8 8 Z M 5.475 4.06 C 6.814 5.399 8 8 8 8 C 8 8 5.399 6.814 4.06 5.475 C 2.721 4.136 1.953 2.734 2.343 2.343 C 2.734 1.953 4.136 2.722 5.475 4.06 Z" 
                    fill="var(--token-28c19fec-f845-4de9-9589-6c35ed54ca73, rgb(255, 255, 255))">
                    </path>
                  </svg>
                  <span>Visit Website</span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideProjects;