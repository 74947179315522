// src/components/Gallery.js
import React, { useEffect, useState } from 'react';
import contentfulClient from '../contentfulClient';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const Gallery = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await contentfulClient.getEntries({
          content_type: 'gallery' // Ensure this matches your content type ID in Contentful
        });

        // Sort the items by the 'order' field in ascending order
        const sortedItems = response.items.sort((a, b) => a.fields.order - b.fields.order);
        setGalleryItems(sortedItems);
      } catch (error) {
        console.error("Error fetching gallery items:", error);
      }
    };

    fetchGalleryItems();
  }, []);

  // Prepare slides array for the lightbox
  const slides = galleryItems.map((item) => ({
    src: item.fields.picture?.fields.file.url,
    alt: item.fields.name,
  }));

  // Function to open lightbox at a specific image
  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="container mt-5">
       <h4 className='ps-3 no-ps-mobile mb-3'>Light, Life and A Hobby</h4>
      <div className="gallery-grid">
       
        {galleryItems.map((item, index) => (
          item.fields.picture ? (
            <div key={item.sys.id} className="single-gallery">
              <div className="gallery-item">
                <img
                  title={item.fields.name}
                  src={item.fields.picture.fields.file.url}
                  onClick={() => openLightbox(index)} // Pass index to open the correct image
                  style={{ cursor: 'pointer' }}
                  alt={item.fields.name}
                />
              </div>
            </div>
          ) : null
        ))}
      </div>

      {/* Lightbox component */}
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={slides}
          index={currentImageIndex} // Start from the clicked image
          onIndexChange={setCurrentImageIndex} // Update the index as user navigates
        />
      )}
    </div>
  );
};

export default Gallery;