import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordProtection = ({ 
  password, 
  children, 
  isLocked = true, 
  onUnlock, 
  themeColor, 
  thumbnail,
  nextProject,
  allItems
}) => {
  const [showContent, setShowContent] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    // Only start countdown if content is locked and not showing
    if (isLocked && !showContent && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (countdown === 0 && isLocked && !showContent) {
      // Only navigate back if we're still on the password screen
      navigate(-1);
    }
  }, [countdown, navigate, isLocked, showContent]);

  // Reset countdown when password protection becomes visible
  useEffect(() => {
    if (isLocked && !showContent) {
      setCountdown(30);
    }
  }, [isLocked, showContent]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === password) {
      setShowContent(true);
      setError('');
      onUnlock(true);
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSkip = () => {
    if (nextProject) {
      navigate(`/case_studies/${nextProject.name}`, {
        state: { 
          portfolioItem: nextProject,
          allItems: allItems // Include allItems in navigation state
        }
      });
    }
  };

  // If content is unlocked or should be shown, return children immediately
  if (!isLocked || showContent) {
    return children;
  }

  return (
    <div className="locked-portfolio-container" 
      style={{ 
        backgroundColor: themeColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <img className='locked-background' alt='bgimage' src={thumbnail} />
      <div className="locked-portfolio-form">
        <div className="form-header">
          <svg className='mb-4' width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 18L11.5 23.5L1.5 18V7L11.5 1.5L21.5 7V18Z" stroke="#8589ac" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.5 10.5H7.5V17.5H15.5V10.5Z" stroke="#8589ac" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 10.5V8L11.5 6.5L14.5 8V10.5" stroke="#8589ac" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5 15.5V13.5" stroke="#8589ac" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          <h2>Protected Content</h2>
          <p>Due to Non-Disclosure Agreement (NDA), this project requires authentication.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              placeholder="Enter password"
              autoComplete="off"
            />
          </div>

          {error && (
            <div className="error-message">
              {error}
            </div>
          )}

          <div className="button-group">
            <button type="submit" className="unlock-button">
              Unlock Content
            </button>
            
            <div className='button-list'>
              {nextProject && (
                <button 
                  type="button" 
                  onClick={handleSkip}
                  className="back-button"
                >
                  Skip to Next Project
                </button>
              )}
              
              <button 
                type="button" 
                onClick={handleGoBack}
                className="back-button-empty"
              >
                Go Back ({countdown}s)
              </button>
            </div>
          </div>

          <p className='text-center mt-4'>
            No password? No stress! <br/> 
            <a className="no-underline" href="mailto:design.hijas@gmail.com">
              <strong>Request one now 👋</strong>
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default PasswordProtection;