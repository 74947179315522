import React, { useEffect, useState, useRef } from "react";

const LazyImage = ({ src, alt, className, onLoad }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);
  const observerRef = useRef(null);  // Store observer reference

  useEffect(() => {
    const currentElement = imgRef.current;

    // Create observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          setIsVisible(true);
          if (currentElement && observerRef.current) {
            observerRef.current.unobserve(currentElement);
          }
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    // Observe element if it exists
    if (currentElement) {
      observerRef.current.observe(currentElement);
    }

    // Cleanup function
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const handleLoad = () => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  };

  const handleError = (e) => {
    console.error(`Error loading image: ${src}`);
    if (e.target) {
      e.target.src = 'fallback-image.png';
    }
  };

  return (
    <div 
      ref={imgRef}
      className={`image-container ${isLoaded ? 'loaded' : ''}`}
    >
      {isVisible && (
        <>
          {!isLoaded && <div className="image-placeholder" />}
          <img
            src={src}
            alt={alt}
            className={`${className} ${isLoaded ? 'fade-in' : ''}`}
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
        </>
      )}
    </div>
  );
};

export default LazyImage;