// src/components/clientss.js
import React, { useEffect, useState } from 'react';
import contentfulClient from '../contentfulClient';

const ClientLogoes = (props) => {
  const [clientsItems, setclientsItems] = useState([]);

  useEffect(() => {
    const fetchclientsItems = async () => {
      try {
        const response = await contentfulClient.getEntries({
          content_type: 'clientLogoes'
        });


        // Sort the items by the 'order' field in ascending order
        const sortedItems = response.items.sort((a, b) => a.fields.order - b.fields.order);
        setclientsItems(sortedItems);
      } catch (error) {
        console.error("Error fetching clients items:", error);
      }
    };

    fetchclientsItems();
  }, []);

  return (
    <div>
      {/* <h2 className='title-sub'> <span>02</span> clients</h2> */}
      <div className="clients-grid row align-items-center justify-content-center">
      <h2 className='title-sub'><span>{props.stepNumber}</span> Where I’ve Left My Mark</h2>

        {clientsItems.map((item) => (
            <div className='col-md-3 single-client' key={item.sys.id}>
                <img title={item.fields.name} className='' src={item.fields.logo.fields.file.url}/>
            </div>
        ))}
      </div>
    </div>
  );
};

export default ClientLogoes;