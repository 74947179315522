import React, { useEffect, useState, useRef } from 'react';
import contentfulClient from '../contentfulClient';

const RotatingElement = React.memo(() => (
  <div className="rotating-text-container">
    <svg viewBox="0 0 100 100" className="rotating-text-svg">
      <path
        id="text-path"
        d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
        fill="none"
      />
      <text className="rotating-text">
        <textPath href="#text-path" startOffset="0">
          ✦ NICE ✦ TO ✦ MEET ✦ YOU
        </textPath>
      </text>
    </svg>
    <div className="rotating-image">
      <img 
        src="https://framerusercontent.com/images/SU7FARlpGIGwn6eTuVOycTV6Q.png" 
        alt="Rotating"
        loading="eager"
      />
    </div>
  </div>
));

const BannerText = ({ text, delay }) => (
  <h1 
    className="banner-text-animated"
    style={{ animationDelay: `${delay}s` }}
  >
    {text}
  </h1>
);

const Banner = () => {
  const [bannerItems, setBannerItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);

  const mainImageContainerRef = useRef(null);


  // scroll 

  useEffect(() => {
    const handleScroll = () => {
      if (mainImageContainerRef.current) {
        const scrollPosition = window.scrollY;
   
        if (scrollPosition > 100) {
          mainImageContainerRef.current.classList.add('scrolled-half');
        } else {
          mainImageContainerRef.current.classList.remove('scrolled-half');
        }
      }
    };
   
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
   }, []);

  // scroll end 


  useEffect(() => {
    const fetchBannerItems = async () => {
      try {
        // Check cache first
        const cached = sessionStorage.getItem('bannerItems');
        if (cached) {
          const { items, timestamp } = JSON.parse(cached);
          const isCacheValid = Date.now() - timestamp < 5 * 60 * 1000; // 5 minutes cache
          
          if (isCacheValid) {
            setBannerItems(items);
            setIsLoading(false);
            return;
          }
        }

        const response = await contentfulClient.getEntries({
          content_type: 'homeBanner',
          include: 2
        });

        // Cache the response
        sessionStorage.setItem('bannerItems', JSON.stringify({
          items: response.items,
          timestamp: Date.now()
        }));

        setBannerItems(response.items);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching banner items:", error);
        setError("Failed to load banner content");
        setIsLoading(false);
      }
    };

    fetchBannerItems();
  }, []);

  useEffect(() => {
    // Add animation class when component mounts
    if (bannerRef.current) {
      bannerRef.current.classList.add('banner-visible');
    }
  }, [bannerItems]);

  if (error) return <div className="banner-error">{error}</div>;
  if (isLoading) return <div className="banner-skeleton" />;

  return (
    <div ref={bannerRef} className="banner-wrapper">
      {bannerItems.map((item) => (
        <div key={item.sys.id} className="container">
          <div className="Banner">
            <div className="banner-content w-100">
              <div className="banner-text-container">
                <BannerText text={item.fields.mainText} delay={0} />
                <BannerText text={item.fields.titleLine2} delay={0.1} />
                <BannerText text={item.fields.titleLine3} delay={0.2} />
              </div>
              
              <div className="banner-paragraph w-50">
                <div 
                  className="banner-text-animated"
                  style={{ animationDelay: '0.3s' }}
                >
                  <h4>{item.fields.paragraph}</h4>
                </div>
              </div>
            </div>

            <div className="right-banner">
              <div className="images banner-image-animated">
                <div ref={mainImageContainerRef} className="main-image-container">
                  <img
                    className="Main-Image main-img"
                    src={item.fields.bannerImage.fields.file.url}
                    alt={item.fields.bannerImage.fields.title || 'Banner'}
                    loading="eager"
                    onLoad={(e) => {
                      e.target.classList.add('loaded');
                    }}
                  />

                  {/* <img
                    className="Main-Image scroll-img"
                    src={item.fields.bannerImage2.fields.file.url}
                    alt={item.fields.bannerImage2.fields.title || 'Banner'}
                    loading="eager"
                    onLoad={(e) => {
                      e.target.classList.add('loaded');
                    }}
                  /> */}
                </div>
                <div className="rotation">
                  <RotatingElement />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Banner);