import React, { useState, useRef, useEffect } from "react";

const DraggableImage = ({ imageUrl, containerHeight = 500, containerWidth = "100%" }) => {
  const [position, setPosition] = useState({ x: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0 });

  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current.getBoundingClientRect();
    const image = imageRef.current.getBoundingClientRect();

    // Set initial position for horizontal drag
    setPosition({
      x: Math.min(0, container.width - image.width),
    });
  }, []);

  const handleStart = (e) => {
    setIsDragging(true);

    // Use `touches[0].clientX` for touch events and `clientX` for mouse events
    const clientX = e.type === "touchstart" ? e.touches[0].clientX : e.clientX;
    setStartPosition({ x: clientX - position.x });
  };

  const handleMove = (e) => {
    if (!isDragging) return;

    // Use `touches[0].clientX` for touch events and `clientX` for mouse events
    const clientX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;

    const container = containerRef.current.getBoundingClientRect();
    const image = imageRef.current.getBoundingClientRect();

    // Calculate new X position
    let newX = clientX - startPosition.x;

    // Constrain horizontal movement
    const minX = container.width - image.width;
    const maxX = 0;
    newX = Math.max(minX, Math.min(newX, maxX));

    setPosition({ x: newX });
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  return (
    <div
      ref={containerRef}
      className="draggable-container"
      style={{
        height: `${containerHeight}px`,
        width: `${containerWidth}`,
      }}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      <img
        ref={imageRef}
        src={imageUrl}
        alt="Draggable Content"
        className="draggable-image"
        style={{
          transform: `translateX(${position.x}px)`,
          cursor: isDragging ? "grabbing" : "grab",
          height: "100%", // Matches the container height
          width: "auto", // Keeps the width proportional
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      />
    </div>
  );
};

export default DraggableImage;