// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import HomePage from './components/HomePage';
import About from './components/About';
import PortfolioDetail from './components/PortfolioDetail';
import ArticleDetail from './components/ArticleDetail';
import CaseStudies from './components/CaseStudies';
import Archive from './components/Archive';
import ErrorPage from './components/Error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/about/", element: <About /> },
      { path: "/Case_Studies/", element: <CaseStudies /> },
      { path: "/Archive/", element: <Archive /> },
      { path: "/Case_Studies/:id", element: <PortfolioDetail /> },
      { path: "/article/:id", element: <ArticleDetail /> },
      { path: "*", element: <ErrorPage /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider 
  router={router} 
  future={{
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }}
/>
  </React.StrictMode>
);