import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className='container mt-4 pt-5 text-center'>
      <h1 className='pt-5 mt-5'>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
      <div className='text-center d-flex justify-content-center'>
        <button className='primary' onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </div>
  );
};

export default ErrorPage;