import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams, Link, useLocation, } from 'react-router-dom';
import contentfulClient from '../contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player';
import DraggableImage from './Comps/DraggableImage';
import Slider from 'react-slick';
import PasswordProtection from './PasswordProtection';

const PortfolioDetail = () => {

  const fallbackPortfolio = {
    reload: 1,
    name: 'Loading...',
    shortDescription: 'Loading description...',
    projectOverview2: 'Loading project overview...',
    innerBannerColor: '#f5f5f5', // Light gray background
    password: null,
    detail: {
      content: [] // Empty content for rich text renderer
    },
    thumbnail: {
      fields: {
        file: {
          url: '#'
        }
      }
    },
    innerBanner: {
      fields: {
        file: {
          url: '#'
        }
      }
    },
    taksLabel: 'Loading,Tags,Here',
    // Add any other properties your components expect
  };


  useEffect(() => {
    setReload(fallbackPortfolio.reload);
  }, []);

  const { id } = useParams();
  const location = useLocation();

  const initialPortfolioItem = location.state?.portfolioItem || fallbackPortfolio;
  const initialAllItems = location.state?.allItems || [];


  const [portfolioItem, setPortfolioItem] = useState(initialPortfolioItem);
  const [allItems, setAllItems] = useState(initialAllItems);
  const [reload, setReload] = useState(1);
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState(null);
  const [nextCaseStudy, setNextCaseStudy] = useState(null);
  const observerRef = useRef(null);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  useEffect(() => {
    let cache = new Map();
    const fetchPortfolioData = async () => {
      try {
        const cacheKey = `portfolio-${id}-${reload}`;

        // Check cache first
        if (cache.has(cacheKey)) {
          console.log('📦 Using cached data');
          const cachedData = cache.get(cacheKey);
          setPortfolioItem(cachedData);
          return;
        }

        console.log('🚀 Starting Contentful request...', new Date().toISOString());

        // Add query parameters to optimize response
        const response = await contentfulClient.getEntries({
          content_type: 'portfolioHij',
          'fields.name': id,
          select: 'fields', // Only get the fields we need
          limit: 1 // Since we only need one item
        });

        if (response.items && response.items.length > 0) {
          const portfolioData = {
            ...response.items[0].fields,
            password: response.items[0].fields.password || null
          };

          // Cache the response
          cache.set(cacheKey, portfolioData);
          setPortfolioItem(portfolioData);
        }

      } catch (error) {
        console.error("❌ Error fetching data:", error);
      }
    };

    fetchPortfolioData();
  }, [id, reload]);


  useEffect(() => {
    if (portfolioItem && allItems.length > 0) {
      const currentIndex = allItems.findIndex(item => item.fields.name === portfolioItem.name);
      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % allItems.length;
        const nextItem = allItems[nextIndex];
        setNextCaseStudy({ id: nextItem.sys.id, ...nextItem.fields });
      }
    }
  }, [portfolioItem, allItems]);

  useEffect(() => {
    if (portfolioItem && portfolioItem.detail) {
      const newHeadings = [];
      portfolioItem.detail.content.forEach((node) => {
        if (
          node.nodeType === BLOCKS.HEADING_3 && 
          node.content && 
          node.content.length > 0 && 
          node.content[0]?.value
        ) {
          const headingText = node.content[0].value.trim();
          if (headingText) {
            const headingId = headingText.toLowerCase().replace(/\s+/g, '-');
            newHeadings.push({ text: headingText, id: headingId });
          }
        }
      });
      setHeadings(newHeadings);
    }
  }, [portfolioItem]);

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveHeading(entry.target.id);
          }
        });
      }, { root: null, rootMargin: '0px', threshold: 0.3 });
    }

    headings.forEach((heading) => {
      const section = document.getElementById(heading.id);
      if (section) {
        observerRef.current.observe(section);
      }
    });

    return () => {
      observerRef.current.disconnect();
      observerRef.current = null;
    };
  }, [headings]);



  const handleSidebarClick = (headingId, offset = 100) => {
    const targetSection = document.getElementById(headingId);
    if (targetSection) {
      const yOffset = -offset;
      const yPosition = targetSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
      setActiveHeading(headingId); // Immediately set active heading
    }
  };

  // Define renderOptions for Contentful rich text
  const renderOptions = {
    renderNode: {
      'embedded-entry-inline': (node) => {
        const entry = node.data.target;

        if (entry.sys.contentType.sys.id === 'prototypeVideos') {
          if (entry.fields.videos && entry.fields.videos.length > 0) {
            return (
              <div className="video-list">
                {entry.fields.videos.map((video, index) => (
                  <div key={index} className='section-left-right'>
                    <video
                      src={video.fields.file.url}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="embed-video"
                    />
                    <div className='video-details'>
                      <strong>{video.fields.title}</strong>
                      <p>{video.fields.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        }

        if (entry.sys.contentType.sys.id === 'imageColumns') {
          const images = entry.fields.images || [];
          if (images.length > 0) {
            return (
              <div className={entry.fields.WrapperClassName + ' row'}>
                {images.map((image, index) => (
                  <div className='col' key={index}>
                    <img
                      src={image.fields.file.url}
                      alt={image.fields.title || 'Multiple Image'}
                      className='w-100'
                    />
                  </div>
                ))}
              </div>
            );
          }
        }

        if (entry.sys.contentType.sys.id === 'imageCarousels') {

          const images = entry.fields.figName || [];
          if (images.length > 0) {
            return (
              <div className='image-carousel'>
                <Slider 
                dots={true} 
                infinite={true} 
                speed={500} 
                arrows={true} 
                slidesToShow={1} 
                slidesToScroll={1}
                
                >
                  {images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image.fields.file.url}
                        alt={image.fields.title || 'Carousel Image'}
                        style={{ width: '100%', borderRadius: '10px' }}
                      />
                      <span className='Slider-fig-name'>{image.fields.title}</span>
                    </div>
                  ))}
                </Slider>
              </div>
            );
          }
        }

        if (entry.sys.contentType.sys.id === 'embed') {
          const embedUrl = entry.fields.embedUrl;
          const fullEmbedCode = entry.fields.fullEmbedCode;

          if (fullEmbedCode) {
            return <div dangerouslySetInnerHTML={{ __html: fullEmbedCode }} />;
          }

          if (embedUrl) {
            return (
              <div className="video-container">
                <ReactPlayer url={embedUrl} controls width="100%" height="500" />
              </div>
            );
          }
        }

        return null;
      },
      'embedded-entry-block': (node) => {
        const entry = node.data.target;

        if (entry.sys.contentType.sys.id === 'embed') {
          const embedUrl = entry.fields.embedUrl;
          const fullEmbedCode = entry.fields.fullEmbedCode;

          if (fullEmbedCode) {
            return <div dangerouslySetInnerHTML={{ __html: fullEmbedCode }} />;
          }

          if (embedUrl) {
            return (
              <div className="video-container">
                <ReactPlayer url={embedUrl} controls width="100%" />
              </div>
            );
          }
        }

        return null;
      },
      [BLOCKS.HEADING_3]: (node) => {
        // Check if content exists and has a non-empty value
        const headingText = node.content[0]?.value?.trim() || '';
        const headingId = headingText ? headingText.toLowerCase().replace(/\s+/g, '-') : '';
        
        return (
          <h3 
            id={headingId} 
            className={headingText === '' ? 'warning-red' : ''}
          >
            {headingText}
          </h3>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // Check if children contains block elements
        const hasBlockElements = node.content.some(item =>
          item.nodeType === 'embedded-entry-inline' ||
          item.nodeType === 'embedded-entry-block' ||
          (item.marks && item.marks.some(mark => mark.type === 'code'))
        );

        if (hasBlockElements) {
          return <div className="content-block">{children}</div>;
        }

        // Check if the paragraph is empty
        const isEmpty = !node.content || node.content.every(child => child.value === '');
        if (isEmpty) {
          return <div className="empty-space">&nbsp;</div>;
        }

        return <p>{children}</p>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { fields } = node.data.target;
        const { description, file, title } = fields;

        if (description === "Draggable") {
          return (
            <div className='embedDragabble'>
              <DraggableImage
                imageUrl={file.url}
                containerHeight={400}
                containerWidth="100%"
              />
              <span>Interactive Image</span>
            </div>
          );
        }

        return (
          <div className="embed-image">
            <img className="w-100 img-rounded" src={file.url} alt={title} />
            <span>{title}</span>
          </div>
        );
      },
    },
  };

  const [isUnlocked, setIsUnlocked] = useState(false);

  // Reset when navigating between portfolios
  useEffect(() => {
    setIsUnlocked(false);
  }, [id]);

  useEffect(() => {
    setIsUnlocked(false);

    // Cleanup function to reset state when component unmounts
    return () => {
      setIsUnlocked(false);
    };
  }, [location.pathname]); // Reset when route changes

  // Add this effect right after your other useEffect hooks
  useEffect(() => {
    let timer;
    if (isUnlocked || !portfolioItem.password) {  // Run for both unlocked and non-password cases
      timer = setTimeout(() => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveHeading(entry.target.id);
            }
          });
        }, {
          root: null,
          rootMargin: '0px',
          threshold: 0.3
        });

        headings.forEach((heading) => {
          const section = document.getElementById(heading.id);
          if (section) {
            observerRef.current.observe(section);
          }
        });
      }, 500);
    }

    return () => {
      if (timer) clearTimeout(timer);
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isUnlocked, headings, portfolioItem.password]);


  const [time, role, impact] = portfolioItem?.timeRoleImpact?.split(',').map(item => item.trim()) || ['', '', ''];


  if (!portfolioItem) return <p>Loading...</p>;

  return (
    <PasswordProtection
       password={portfolioItem.password} 
        isLocked={!!portfolioItem.password}
        onUnlock={setIsUnlocked}
        themeColor={portfolioItem.innerBannerColor}
        thumbnail={portfolioItem.thumbnail?.fields.file.url}
        nextProject={nextCaseStudy}
        allItems={allItems} // Add this line
    >





      <div className={`portfolio-detail-page ${portfolioItem.password && !isUnlocked ? 'locked' : ''}`}>
        <div className="portfolio-detail-page">
          <div className='portfolio-Banner' style={{ backgroundColor: portfolioItem.innerBannerColor }}>
            <div></div>
            <div className='side-info row justify-content-center text-center'>
              <div className='col-md-6'>
                <h2>{portfolioItem.name}</h2>
                <p>{portfolioItem.shortDescription}</p>
                <div className='data-bottom'>
                  <div className='single-info'>
                    <div className='info-title'>
                      <span>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10" />
                          <polyline points="12 6 12 12 16 14" />
                        </svg>
                      </span>
                      <strong>Timeline</strong>
                    </div>
                    <div className='info-value'>{time}</div>
                  </div>

                  <div className='single-info'>
                    <div className='info-title'>
                      <span>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                          <circle cx="9" cy="7" r="4" />
                          <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        </svg>
                      </span>
                      <strong>Role</strong>
                    </div>
                    <div className='info-value'>{role}</div>
                  </div>

                  <div className='single-info'>
                    <div className='info-title'>
                      <span>
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <circle cx="12" cy="12" r="10" />
                          <circle cx="12" cy="12" r="6" />
                          <circle cx="12" cy="12" r="2" />
                        </svg>
                      </span>
                      <strong>Impact</strong>
                    </div>
                    <div className='info-value'>{impact}</div>
                  </div>
                </div>
              </div>
              {portfolioItem.thumbnail && (
                <img
                  className="Main-Portfolio-Image"
                  src={portfolioItem.innerBanner
                    ? portfolioItem.innerBanner.fields.file.url
                    : portfolioItem.thumbnail.fields.file.url
                  }
                  alt={portfolioItem.title}
                />
              )}
            </div>
          </div>


          <div className='container overview-section'>
            <div className='row justify-content-between'>
              <div className='col-md-6 mb-4'>
                <h4>Project Overview </h4>
                <p>
                  {portfolioItem.projectOverview2}


                </p>
              </div>

              <div className='col-md-5 mb-4'>
                <div className='label-wrap'>

                  {portfolioItem.taksLabel?.split(',').map((label, index) => (
                    <span key={`task-${index}`}>{label.trim()}</span>
                  ))}

                </div>
              </div>




            </div>
            <hr className='mt-2 op-1' />
          </div>



          <div className='container'>


            <div className="row">
              <div className="col-md-3 sidebar">
                <nav>
                  <ul>
                    {headings.map((heading, index) => (
                      <li key={index}>
                        <button
                          onClick={() => handleSidebarClick(heading.id)}
                          className={activeHeading === heading.id ? 'active' : ''}
                        >
                          <span></span> {heading.text}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-9 content">
                {/* <p>{portfolioItem.projectType}</p> */}

                <div>
                  {portfolioItem.detail && documentToReactComponents(portfolioItem.detail, renderOptions)}
                </div>
              </div>
            </div>
          </div>






          {nextCaseStudy && (
            <div className="container">
              <Link
                className='wrap-next-case container-fluid'
                to={`/case_studies/${nextCaseStudy.name}`}
                state={{ portfolioItem: nextCaseStudy, allItems }}
              >
                <div
                  className="next-case-study mt-5 pt-10 text-center"
                  style={{ backgroundColor: nextCaseStudy.innerBannerColor }}
                >
                  <div className='details'>
                    <p>Next Case Study</p>
                    <h4>{nextCaseStudy.name}</h4>
                  </div>
                  {nextCaseStudy.thumbnail && (
                    <img src={nextCaseStudy.thumbnail.fields.file.url} alt={nextCaseStudy.name} />
                  )}
                </div>
              </Link>
            </div>
          )}
        </div>

      </div>
    </PasswordProtection>
  );
};

export default PortfolioDetail;