import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.scss';
import ReactGA4 from 'react-ga4';

import MainNav from './components/NavBar';
import Footer from './components/Footer';
import ScrollManager from './components/ScrollManager';
import StarRating from './components/Comps/StarRating';
import { NavigationProvider } from './components/Comps/NavigationContext';
import VisitorTracker from './components/Comps/VisitorTracker';

function App() {
    const location = useLocation();
    const [isScrollRestored, setIsScrollRestored] = useState(true);
    const [loading, setLoading] = useState(true);
    const [ratingPopupVisible, setRatingPopupVisible] = useState(true);
    const nodeRef = useRef(null);

    const handleScrollRestored = () => {
        setIsScrollRestored(true);
    };

    useEffect(() => {
        ReactGA4.initialize('G-S868L1LSPX');
        ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

        if (document.referrer.includes('mhijas.com')) {
            ReactGA4.event({
                category: 'Domain Redirect',
                action: 'Redirect from mhijas.com',
            });
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 100);
        return () => clearTimeout(timer);
    }, [location.key]);

    const appContent = (
        <div className="App">
            <ScrollManager onScrollRestored={handleScrollRestored} />
            <MainNav />

            <main className="main-content">
                <TransitionGroup>
                    <CSSTransition
                        nodeRef={nodeRef}
                        key={location.key}
                        classNames="fade"
                        timeout={300}
                        unmountOnExit
                    >
                        <div ref={nodeRef}>
                            {loading ? (
                                <div className="loading-placeholder">Loading...</div>
                            ) : (
                                <Outlet />
                            )}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </main>

            <Footer />

            {ratingPopupVisible && (
                <StarRating 
                    onClose={() => setRatingPopupVisible(false)}
                />
            )}

            <VisitorTracker/>

        </div>
    );

    return (
        <NavigationProvider>
            {appContent}
        </NavigationProvider>
    );
}

export default App;