import React, { useEffect, useState, useRef, useCallback } from "react";
import contentfulClient from "../contentfulClient";

const Archive = () => {
  const [communityItems, setCommunityItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const imagesLoadedRef = useRef({}); // Persist image load states
  const imageCache = useRef(new Map()); // In-memory image cache
  const [forceRender, setForceRender] = useState(0); // Trigger re-renders

  // Cache the image URL in memory
  const cacheImage = useCallback((url) => {
    if (!imageCache.current.has(url)) {
      const img = new Image();
      img.src = url;
      imageCache.current.set(url, img);
    }
  }, []);

  // Fetch archive items and handle caching
  useEffect(() => {
    const fetchCommunityItems = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await contentfulClient.getEntries({
          content_type: "archives",
        });

        let sortedItems = response.items.map((item, index) => ({
          ...item,
          fields: {
            ...item.fields,
            order: item.fields.order ?? index + 1,
          },
        }));

        sortedItems = sortedItems.sort((a, b) => a.fields.order - b.fields.order);

        // Preload images
        sortedItems.forEach((item) => {
          if (item.fields.image?.fields.file.url) {
            cacheImage(item.fields.image.fields.file.url);
          }
        });

        setCommunityItems(sortedItems);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching community items:", err);
        setError("Failed to load archive items. Please try again later.");
        setLoading(false);
      }
    };

    fetchCommunityItems();
  }, [cacheImage]);

  // Handle image load states
  const handleImageLoad = (id) => {
    imagesLoadedRef.current[id] = true;
    setForceRender((prev) => prev + 1); // Trigger re-render
  };

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Reload</button>
      </div>
    );
  }

  return (
    <div className="mt-4 pt-4 mb-4 pb-4 archive-wrapper">
      <div className="container mt-5 pt-5 mb-5 pb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="name-heading">Archive</h1>
            <div className="banner-text-animated" style={{ animationDelay: `0.1s` }}>
              <h4>
                Beyond the featured case studies, a collection of selected
                projects I've crafted over the years.
              </h4>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="container">
          <div className="row">
            {[1, 2, 3].map((i) => (
              <div key={i} className="col-md-4">
                <div className="wrap border_r skeleton">
                  <div className="thumbnail skeleton-animation"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row row-archive fade-in">
            {communityItems.map((item) => (
              <div className="col-md-4" key={item.sys.id}>
                <div className="wrap border_r">
                  <div
                    className="thumbnail"
                    style={{
                      position: "relative",
                      paddingBottom: "66.67%",
                      background: "#f5f5f5",
                      overflow: "hidden",
                    }}
                  >
                    {item.fields.image && (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={item.fields.url}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          className={`w-100 ${
                            imagesLoadedRef.current[item.sys.id] ? "loaded" : "loading"
                          }`}
                          alt={item.fields.projectName}
                          src={
                            imageCache.current.has(item.fields.image.fields.file.url)
                              ? imageCache.current.get(item.fields.image.fields.file.url).src
                              : item.fields.image.fields.file.url
                          }
                          onLoad={() => handleImageLoad(item.sys.id)}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            opacity: imagesLoadedRef.current[item.sys.id] ? 1 : 0,
                            transition: "opacity 0.3s ease",
                          }}
                        />
                      </a>
                    )}
                  </div>
                  <div className="community-item">
                    <div className="information">
                      <div className="p-4">
                        <h4>{item.fields.projectName}</h4>
                        <div className="label-wrap mt-3">
                          {item.fields.tags?.split(",").map((label, index) => (
                            <span key={`task-${index}`}>{label.trim()}</span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Archive;